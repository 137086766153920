import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/boiserie',
    name: 'boiserie',
    component: () => import('@/views/Boiserie.vue'),
  },
  {
    path: '/demoussage',
    name: 'demoussage',
    component: () => import('@/views/Demoussage.vue'),
  },
  {
    path: '/facade',
    name: 'facade',
    component: () => import('@/views/Facade.vue'),
  },
  {
    path: '/gouttiere',
    name: 'gouttiere',
    component: () => import('@/views/Gouttiere.vue'),
  },
  {
    path: '/peinture',
    name: 'peinture',
    component: () => import('@/views/Peinture.vue'),
  },
  {
    path: '/reparation',
    name: 'reparation',
    component: () => import('@/views/Reparation.vue'),
  },
  {
    path: '/velux',
    name: 'velux',
    component: () => import('@/views/Velux.vue'),
  },
  {
    path: '/realisations',
    name: 'realisations',
    component: () => import('@/views/Realisations.vue'),
  },
  {
    path: '/mentions',
    name: 'legal-mentions',
    component: () => import('@/views/Mentions.vue'),
  },
  {
    path: '/politique',
    name: 'confidential-policy',
    component: () => import('@/views/Confidential.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
        top: 100,
      }
    }
  },
})

export default router
