<template>
 <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | RD Peinture 66` : `RD Peinture 66` }}</template>
  </metainfo>
    <Navigation></Navigation>
    <Head></Head>
    <Contact></Contact>
    <Card></Card>
    <router-view />
    <Scroll></Scroll>
    <Intervention></Intervention>
    <Foot></Foot>
</template>

<script>
import Navigation from '@/components/Navigation.vue'
import Head from '@/components/Head.vue'
import Contact from '@/components/Contact.vue'
import Foot from '@/components/Foot.vue'
import Scroll from '@/components/Scroll.vue'
import Card from '@/components/Card.vue'
import Intervention from '@/components/Intervention.vue'
import { useMeta } from 'vue-meta'

export default {
  name: 'App',
  components: {
    Navigation,
    Head,
    Contact,
    Foot,
    Scroll,
    Card,
    Intervention
  },
  setup() {
     useMeta({
      title: 'Couverture',
      htmlAttrs: { lang: 'fr', amp: false }
    })
  }
}

</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
