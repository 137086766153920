<template>
  <button
    @click="moveUp()"
    v-show="scY > 300"
    id="myBtn"
    title="Retour haut de page"
  >
    <i class="bi bi-arrow-up-circle fa-6x"></i>
  </button>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'scroll',
  data() {
    return {
      scTimer: 0,
      scY: 0,
    }
  },
  methods: {
    moveUp() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    handleScroll: function () {
      if (this.scTimer) return
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY
        clearTimeout(this.scTimer)
        this.scTimer = 0
      }, 100)
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
})
</script>
<style lang="scss">
#myBtn {
  display: block; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  right: 30px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  background-color: #009ee0; /* Set a background color */
  color: white; /* Text color */
  cursor: pointer; /* Add a mouse pointer on hover */
  //   padding: 15px; /* Some padding */
  border-radius: 10px; /* Rounded corners */
  font-size: 2rem; /* Increase font size */
}

#myBtn:hover {
  background-color: #555; /* Add a dark-grey background on hover */
}

@media (max-width: 992px) {
  #myBtn {
    bottom: 10px;
    right: 5px;
    font-size: 1rem;
  }
}
</style>
