<template>
  <header>
    <section class="col-md-8 mx-auto">
      <div class="d-flex text-center">
        <div class="align-self-center col-4">
          <img
            src="@/assets/img/logos.png"
            alt="logos"
            class="img-fluid logos"
          />
        </div>

        <div class="align-self-center col-4">
          <router-link to="/">
            <img
              src="@/assets/img/logo.png"
              alt="logo"
              class="img-fluid logo"
            />
          </router-link>
        </div>

        <div class="align-self-center col-4 fs-2">
          <i class="bi bi-telephone icon-tel"></i>
          <span class="text-primary">
            <a href="tel:+33781962743"> 07 81 96 27 43</a>
          </span>
        </div>
      </div>
    </section>
  </header>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'header-component',
})
</script>

<style lang="scss">
.icon-tel {
  color: #009ee0;
}
.logo {
  max-height: 100px !important;
}
.logos {
  max-height: 150px !important;
}
</style>
