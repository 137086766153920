<template>
  <section>
    <div class="map flex">
    <scrollParallax>


    <Carousel :autoplay="5000" :wrap-around="true">
    <Slide v-for="slide in img" :key="slide" class="">
      <img
        :src="require(`../assets/img/${slide}`)"
        alt="couverture-RD Peinture" class="img-caroussel"
      />
    </Slide>

    <template #addons>
      <Pagination />
    </template>
  </Carousel>
  </scrollParallax>
  <div  class="button-position col-3">
   <router-link   :to="{
                  path: '/realisations',
                  hash: '#anchor',
                }"><button class="btn btn-primary rounded">Nos réalisations</button></router-link>
<h2 class="text-primary text-contact">RD Peinture à votre service pour</h2>
    <v-typical
    class="blink text-primary text-contact"
    :steps="['Couverture', 1000, 'Démoussage et nettoyage de toiture', 500, 'Pose et entretien des gouttières', 1000, 'Pose et réparation vélux', 500, 'Réparation urgente fuite', 1000, 'Ravalement de facade', 500, 'Peinture intérieure', 1000, 'Rénovation boiserie et ferronerie', 500]"
    :loop="Infinity"
    :wrapper="'h2'"
  ></v-typical>
   
  </div>
      <div class="bg-light form-position border rounded">
        <h2 class="bg-primary text-white">Devis gratuit</h2>
        <form class="container" id="contact">
        <p v-if="errors.length" class="alert alert-danger">
          <b>Veuillez compléter ces champs :</b>
          <ul>
            <li v-for="error in errors" :key="error.id">{{ error }}</li>
          </ul>
        </p>
          <div class="form-row">
            <div class="col">
              <label for="name">Votre nom *</label>
              <input
                v-model="name"
                type="text"
                name="name"
                class="form-control"
              />
            </div>
            <div class="col">
              <label for="cp">Votre code postale *</label>
              <input v-model="cp" type="text" name="cp" class="form-control" />
            </div>
            <div class="col">
              <label for="email">Votre email *</label>
              <input
                v-model="email"
                type="text"
                name="email"
                class="form-control"
              />
            </div>
            <div class="col">
              <label for="phone">Votre téléphone *</label>
              <input
                v-model="phone"
                type="text"
                name="phone"
                class="form-control"
              />
            </div>
            <div class="col">
              <label for="intervention">Intervention souhaité *</label>
              <select
                v-model="subject"
                name="intervention"
                class="form-control"
              >
                <option selected value="">Choisissez une intervention</option>
                <option value="couverture">Couverture</option>
                <option value="urgence">Urgence fuite</option>
                <option value="traitement">
                  Nettoyage et traitement toiture
                </option>
                <option value="gouttiere">Pose et entretien gouttières</option>
                <option value="velux">Pose et entretien vélux</option>
              </select>
            </div>
            <div class="col">
              <label for="message">Message</label>
              <textarea
                v-model="message"
                name="message"
                class="form-control"
              ></textarea>
            </div>
          </div>
          <button
            v-if="(this.success == false)"
            type="button"
            @click="checkForm()"
            class="btn btn-primary my-4"
          >
            Envoyer
          </button>
          <div v-else>
            <div class="alert alert-success">
              <p>Votre message a bien été envoyé</p>
              <p>Nous vous recontacterons dans les plus brefs délais</p>
            </div>
            <button
              type="button"
              @click="this.success = false"
              class="btn btn-primary my-4"
            >
              Envoyer un autre message ?
            </button>
          </div>
          <p>* Champs obligatoires</p>
          <a href="">Données personnelles et confidentialité</a>
        </form>
      </div>
    </div>
  </section>
  
</template>
<script>
import { defineComponent } from 'vue'
import '@/styles/custom.scss'
import axios from 'axios'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination } from 'vue3-carousel'
import ScrollParallax from 'vue3-parallax/src/components/ScrollParallax.vue';
import VTypical from 'vue-typical';

export default defineComponent({
  name: 'contact',
  components: {   
    Carousel,
    Slide,
    Pagination,
       VTypical,
       ScrollParallax
    },
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      message: '',
      subject: '',
      cp: '',
      success: false,
      errors: [],
      img: ['carousel-1.jpg', 'carousel-2.jpg', 'carousel-3.jpg',  'carousel-4.jpg'],
    }
  },
  methods: {
    sendContact() {
      let data = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        message: this.subject,
        subject: this.subject,
        cp: this.cp,
      }
      axios
        // .post('http://localhost/ruben-peinture/email-vue.php', {
          .post('https://admin.rd-peinture66.com/email-vue.php', {
          params: data,
        })
        .then((response) => {
          this.success = response.data.success
        })
        .catch((e) => {
          console.log(e)
        })
    },
    checkForm() {
      this.errors = []

      if (!this.name) {
        this.errors.push('Nom requis')
      }
      if (!this.cp) {
        this.errors.push('Code postal requis')
      }
      if (!this.email) {
        this.errors.push('Email requis')
      }
      if (!this.phone) {
        this.errors.push('Téléphone requis')
      }
      if (!this.subject) {
        this.errors.push('Sujet requis')
      }
      if (!this.message) {
        this.errors.push('Message requis')
      }

      if (!this.errors.length) {
        this.sendContact()
      }
    },
  },
})
</script>

<style lang="scss">
hr {
  margin: 0;
  height: 3px;
  opacity: 0.7;
}

.form-position {
  position: absolute;
  top: 5%;
  right: 10%;
  width: 30%;
  padding-right: 0%;
}

.button-position {
  position: absolute;
  top: 15%;
  left: 20%;
  width: 10%;
}

.map {
  position: relative;
}

.col {
  margin: 1.5rem;
}

ul {
  padding-left: 0 !important;
  list-style:none;
}

.caroussel-container {
  max-height: 900px;
  width: 100%;
}

.img-caroussel {
  width: 100%;
  max-height: 1920px;
  height:800px
}

.text-contact {
  text-shadow: rgb(0, 0, 0) 2px 0px 0px;
}

@media (max-width: 992px) {
  .form-position {
    position: absolute;
    width: 80%;
    margin: 0 auto;
  }
}
</style>
