<template>
  <section class="sticky-top">
    <nav class="navbar navbar-expand-lg navbar-primary bg-light">
      <div class="container-fluid">
        <button
          class="navbar-toggler bi bi-list humburger"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        ></button>
        <div
          v-bind:class="{ active: navOpen }"
          class="collapse navbar-collapse"
          id="navbarNavAltMarkup"
        >
          <div class="navbar-nav mx-auto text-black-50 align-items-center">
            <router-link
              @click="manageNav()"
              :to="{
                path: '/',
                hash: '#anchor',
              }"
              class="nav-link btn-outline-primary rounded"
            >
              Couverture
            </router-link>
            <div v-for="item in menuList" :key="item.id">
              <router-link
                @click="manageNav()"
                class="nav-link btn-outline-primary rounded menu-name"
                :to="{
                  path: item.link,
                  hash: '#anchor',
                }"
              >
                <span>{{ item.name }}</span>
              </router-link>
            </div>
            <button
              @click="moveUp(), manageNav()"
              class="btn-outline-primary my-2 my-sm-0 rounded"
            >
              Contactez-nous
            </button>
          </div>
        </div>
      </div>
    </nav>
  </section>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'navigation',
  methods: {
    moveUp() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    manageNav() {
      this.navOpen = !this.navOpen
    },
  },
  data() {
    return {
      menuList: [
        {
          name: 'Démoussage et nettoyage toiture',
          link: 'demoussage',
        },
        {
          name: 'Pose et entretien des gouttières',
          link: 'gouttiere',
        },
        {
          name: 'Pose et réparation vélux',
          link: 'velux',
        },
        {
          name: 'Réparation urgente fuite',
          link: 'reparation',
        },
        {
          name: 'Ravalement facade',
          link: 'facade',
        },
        {
          name: 'Peinture intérieure',
          link: 'peinture',
        },
        {
          name: 'Rénovation boiserie et ferronerie',
          link: 'boiserie',
        },
        {
          name: 'Réalisations',
          link: 'realisations',
        },
      ],
      navOpen: false,
    }
  },
})
</script>

<style lang="scss">
a {
  text-decoration: none !important;
}

.menu-name:hover {
  color: black !important;
}

.humburger {
  margin: auto;
}

button:focus-visible {
  outline-color: #009ee0 !important;
}
</style>
