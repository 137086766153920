<template>
  <section class="bg-img-intervention img-height">
    <div class="d-flex py-5">
      <div class="container align-self-center text-center map-intervention">
        <div>
          <h2>Notre zone d'intervention</h2>
          <img
            class="img-fluid"
            src="@/assets/img/zone.jpg"
            alt="zone d'intervention"
          />
          <p>Intervention à 50 km autour de Pia</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'intervention',
})
</script>

<style lang="scss">
.map-intervention {
  background-color: rgba(255, 255, 255, 0.8);
}

.bg-img-intervention {
  background-image: url('~@/assets/img/deco-2-2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
