<template>
  <section>
    <div class="container">
      <div class="row text-center">
        <div class="col-md-4 align-self-center">
          <p>
            Spécialisée dans les travaux de toiture, l’entreprise RD Peinture
            vous propose la pose, la rénovation et l’entretien de votre
            couverture. Nous intervenons dans les alentours de Perpignan
          </p>
        </div>
        <div class="col-md-4 align-self-center">
          <img src="@/assets/img/logo.png" alt="logo" class="img-fluid" />
        </div>
        <div class="col-md-4 align-self-center">
          <p>
            Demandez votre devis gratuit pour vos travaux de couverture près de
            Perpignan : <a href = "mailto: r.d.peinture66@gmail.com">r.d.peinture66@gmail.com</a>
          </p>
          <i class="bi bi-telephone icon-tel"></i>
          <span class="text-primary">
             <a href="tel:+33781962743">07 81 96 27 43</a>
          </span>
        </div>
      </div>
    </div>
    <div class="bg-footer">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center text-footer">
            <p>
              <router-link :to="{ name: 'legal-mentions', hash: '#anchor' }">
                Mentions légales
              </router-link>
              -
              <router-link :to="{ name: 'home', hash: '#contact' }">
                Contact
              </router-link>

              -
              <router-link
                :to="{ name: 'confidential-policy', hash: '#anchor' }"
              >
                Politiques de confidentialité
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'foot',
  methods: {
    moveUp() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
  },
})
</script>

<style lang="scss">
.bg-footer {
  background-color: #323232;
  padding: 1%;
}

.text-footer {
  color: white;
  text-align: center;
  margin-bottom: 0rem;
}

.link {
  color: #0d6efd;
  cursor: pointer;
}
</style>
