<template>
  <section class="py-5">
    <div class="container">
      <div class="row">
        <div v-for="item in menuList" :key="item.name" class="col-md-3 mt-3">
          <div class="card">
            <router-link
              :to="{
                path: item.link,
                hash: '#anchor',
              }"
            >
              <img
                class="card-img-top img-fluid"
                :src="require(`@/assets/img/${item.img}`)"
                :alt="item.title"
              />
              <div class="card-body row align-content-center">
                <p class="card-text text-center">{{ item.title }}</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'card',
  data() {
    return {
      menuList: [
        {
          title: 'Couverture',
          link: '/',
          img: 'img-10-2.jpg',
        },
        {
          title: 'Démoussage et nettoyage toiture',
          link: '/demoussage',
          img: 'IMG-2218.jpg',
        },
        {
          title: 'Pose et réparation vélux',
          link: '/velux',
          img: 'onglet-velux.jpg',
        },
        {
          title: 'Réparation urgente fuite',
          link: '/reparation',
          img: 'onglet-reparation.jpg',
        },
        {
          title: 'Ravalement facade',
          link: '/facade',
          img: 'IMG-2020.jpg',
        },
        {
          title: 'Peinture intérieure',
          link: '/peinture',
          img: 'IMG-2447.jpg',
        },
        {
          title: 'Rénovation boiserie et ferronerie',
          link: '/boiserie',
          img: 'IMG-2635.jpg',
        },
        {
          title: 'Pose et entretien des gouttières',
          link: '/gouttiere',
          img: 'onglet-goutieres.jpg',
        }
      ],
    }
  },
})
</script>

<style lang="scss">

.card-img-top {
  max-height: 150px;
}

.card-body {
  height: 80px;
}
</style>
