import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/styles/bootstrap-custom.scss'
import '@/styles/custom.scss'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.js'
import { createMetaManager }  from 'vue-meta'

// createApp(App).use(router, createMetaManager ).mount('#app')

const app = createApp(App)
  .use(router)
  .use(createMetaManager()) // add this line

router.isReady()
app.mount('#app')